<template>

  <div class="block-slide-toggle__content">
    <ul class="block-list">
      <li 
        v-for="(list, index) in lists" 
        :key="index"
        :data-id="list.id"
        class="block-list__item"
        :class="name.mode"
      >
        <div style="padding: 10px 0" @click.prevent="openList('update', index)">
          <!-- auto -->
          <div v-if="name.mode == 'auto'">
            <h5 style="margin: 0;text-transform: uppercase;">{{list.vin_code}}</h5>
          </div>

          <!-- containers -->
          <div v-if="name.mode == 'containers'">
            <h5 style="display: flex;margin: 0;text-transform: uppercase;">{{list.number}}<span style="margin-left: 10px">{{list.type_name}}</span></h5>
          </div>

          <!-- services -->
          <div v-if="name.mode == 'services'">
            <h5 style="margin: 0">{{list.service_name}} * {{list.num}}<div style="display:flex;position:relative;bottom:-10px"><span>{{$root.langText.input.expense}}: {{(+list.sum_outgo).toFixed(0)}} {{list.currency_name}}</span><span style="margin-left: 20px"> {{$root.langText.input.client2}}: {{(+list.sum_income).toFixed(0)}} {{list.currency_income_name}}</span></div></h5>
          </div>


        </div>
        <!-- arrow -->
        <a class="icon icon-arrow-right" v-if="canEdit" @click.prevent="openDelete(index, list.id)"></a>
        <div class="block-list__btn">
          <a class="icon icon-copy block-list__btn__copy" @click.prevent="openList('copy', index)"></a>
          <a class="icon icon-delete block-list__btn__delete" @click.prevent="openList('delete', index)"></a>
        </div>
      </li>
    </ul>
    <a class="btn btn-transparent" v-if="canEdit" @click.prevent="openList('create', index)">{{$root.langText.btnAdd}}<div class="icon-plus"></div></a>
    <InformationPlates v-if="InformationPlates" :list="{lists, name, mod, canEdit, id: data.id, total_sums_currency_id: data.total_sums_currency_id, index, modCreate, mod}" @close="closeList"/>
  </div>
</template>






<script>
import InformationPlates from '@/components/agreementsOther/InformationPlates'


export default {
  props: ['data', 'name', 'modCreate', 'canEdit'],


  data:() => ({
    lists: {},
    InformationPlates: false,
    mod: 'create',
    index: '',
    activeClass: false,
  }),


  watch:{
    data: function(){
      this.lists = this.data[this.name.bd_name];
    }
  },


  async created(){
    this.lists = this.data[this.name.bd_name]
  },



  methods: {

    openDelete(index, id){
      var options = document.querySelectorAll("li."+ this.name.mode); 
      options.forEach(function(item, i){
        if(i === index){
          item.classList.toggle('active')
        }
      });
    },

    
    openList(mod, index){
      this.mod = mod
      this.index = index
      this.InformationPlates = !this.InformationPlates
    },
    closeList(data){
      this.InformationPlates = !this.InformationPlates
      if(data){
        this.$emit('update')
      }
    }
  },



  components: {
    InformationPlates
  }
}
</script>